import { yupResolver } from "@hookform/resolvers/yup";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Moment from "moment";
import {
  Button,
  Checkbox,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Typography,
  CircularProgress,
  Link,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Controller, useForm } from "react-hook-form";
import userDetailsValidationSchema from "../../../util/validations/userDetailsValidation";
import SelectDropDown from "./../../../examples/DropDown/index";
import { DataGrid } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOrganizationsDropdown } from "layouts/common/Redux/actionCreator";
import { getJobApplicationByIdService } from "shared/services";
import { getJobJobsList, updateJobApplication } from "../Redux/actionCreator";
import { setUsersDropdown } from "layouts/common/Redux/actionCreator";
import MDButton from "components/MDButton";
import moment from "moment";
import dayjs from "dayjs";
import SelectDropDownNew from "examples/DropDownNew";
function ApplicationDetailsById() {
  const ID = window.location.href.split("application")[1].split("/")[1];
  const getData = async () => {
    let response = await getJobApplicationByIdService(ID);
    let n = response[0]?.createdAt;

    setResponseData(response);

    return response[0];
  };
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema),
    mode: "onSubmit",
    defaultValues: () => getData(),
  });
  const formValues = getValues();

 

  const selectOptions = [
    {
      key: "OPEN",
      value: "OPEN",
    },
    {
      key: "ACCEPTED",
      value: "ACCEPTED",
    },
    {
      key: "REJECTED",
      value: "REJECTED",
    },
    {
      key: "PENDING_TEST",
      value: "PENDING_TEST",
    },
    {
      key: "REVIEW",
      value: "REVIEW",
    },
    {
      key: "PENDING_INTERVIEW",
      value: "PENDING_INTERVIEW",
    },
    {
      key: "FINISHED",
      value: "FINISHED",
    },
    {
      key: "WITHDRAW",
      value: "WITHDRAW",
    },
  ];

  // Applied At DateTime

  const [appliedAtDateTime, setAppliedAtDateTime] = useState(null);

  const handleAppliedAtDateTime = (newValue) => {
    setAppliedAtDateTime(newValue);
  };

  const handleAppliedAtDateTimeNow = () => {
    setAppliedAtDateTime(Date().now);
  };

  // Interview At DateTime

  const [interviewAtDateTime, setInterviewAtDateTime] = useState(null);

  const handleInterviewAtDateTime = (newValue) => {
    setInterviewAtDateTime(newValue);
  };

  const handleInterviewAtDateTimeNow = () => {
    setInterviewAtDateTime(Date().now);
  };

  // Test Request At DateTime

  const [testRequestAtDateTime, setTestRequestAtDateTime] = useState(null);

  const handleTestRequestAtDateTime = (newValue) => {
    setTestRequestAtDateTime(newValue);
  };

  const handleTestRequestAtDateTimeNow = () => {
    setTestRequestAtDateTime(Date().now);
  };

  // Interview Request At DateTime

  const [interviewRequestAtDateTime, setInterviewRequestAtDateTime] =
    useState(null);

  const handleInterviewRequestAtDateTime = (newValue) => {
    setInterviewRequestAtDateTime(newValue);
  };

  const handleInterviewRequestAtDateTimeNow = () => {
    setInterviewRequestAtDateTime(Date().now);
  };

  //Fetching Organization Dropdown Details
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { OrganizationsData } = useSelector((state) => state.common);
  const organizationInfo = OrganizationsData?.organizationsInfo;

  const organizationOptions = [];

  const { UsersData } = useSelector((state) => state.common);
  const userInfo = UsersData?.usersInfo;

  const userOptions = [];

  userInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    userOptions.push(obj);
  });

  organizationInfo.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    organizationOptions.push(obj);
  });

  const { job_Data } = useSelector((state) => state.job);
  const jobCode = job_Data?.job_Data_info;

  let jobOptions = [];

  jobCode.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.code,
    };

    jobOptions.push(obj);
  });
  const [job, setJob] = useState("");
  const handleJobChange = (e) => {
    let userID;

    jobOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setJob(userID);
      }
    });
  };

  const [organization, setOrganization] = useState("");
  const handleOrganizationChange = (e) => {
    let userID;
    organizationOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setOrganization(userID);
      }
    });
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const [pro, setPro] = useState("");
  const handleProfessionalChange = (e) => {
    let userID;
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
        setPro(userID);
      }
    });
  };

  useEffect(() => {
    dispatch(setOrganizationsDropdown());
    dispatch(setUsersDropdown());
    dispatch(getJobJobsList());
  }, []);

  const columns = [
    { field: "date", headerName: `Date`, flex: 1 },
    { field: "professionalName", headerName: `Professional Name`, flex: 1 },
    { field: "shiftStartTime", headerName: `Shift Start Time`, flex: 1 },
    { field: "shiftEndTime", headerName: `Shift End Time`, flex: 1 },
    {
      field: "checkInTime",
      renderHeader: () => (
        <strong>
          {`Check In Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),
      editable: true,
      flex: 1,
    },
    {
      field: "checkOutTime",
      renderHeader: () => (
        <strong>
          {`Check In Time`}
          &nbsp; &nbsp;
          <Edit />
        </strong>
      ),

      editable: true,
      flex: 1,
    },
  ];
  const rows = [
    {
      id: 1,
      date: "Date 1",
      professionalName: "Professional 1",
      shiftStartTime: "9:00",
      shiftEndTime: "18:00",
      checkInTime: "08:57",
      checkOutTime: "17:50",
    },
    {
      id: 2,
      date: "Date 1",
      professionalName: "Professional 1",
      shiftStartTime: "9:00",
      shiftEndTime: "18:00",
      checkInTime: "08:57",
      checkOutTime: "",
    },
  ];

  const [responseData, setResponseData] = useState([]);

  console.log("responseData", responseData[0])

  const onSubmit = (data) => {
    const payload = {
      appliedAt:
        typeof data?.appliedAt == "object"
          ? data?.appliedAt?.ts.length > 0
            ? new Date(parseInt(data.appliedAt.ts)).toISOString()
            : ""
          : data?.appliedAt,

      testRequestAt:
        typeof data?.testRequestAt == "object"
          ? data?.testRequestAt?.ts.length > 0
            ? new Date(parseInt(data.testRequestAt.ts)).toISOString()
            : ""
          : data?.testRequestAt,
      approvedAt:
        typeof data?.approvedAt == "object"
          ? data.approvedAt.ts.length > 0
            ? new Date(parseInt(data.approvedAt.ts)).toISOString()
            : ""
          : data.approvedAt,

      interviewStart:
        typeof data?.interviewStart == "object"
          ? data?.interviewStart?.ts.length > 0
            ? new Date(parseInt(data.interviewStart.ts)).toISOString()
            : ""
          : data.interviewStart,

      interviewRequestAt:
        typeof data?.interviewRequestAt == "object"
          ? data?.interviewRequestAt?.ts.length > 0
            ? new Date(parseInt(data.interviewRequestAt.ts)).toISOString()
            : ""
          : data.interviewRequestAt,

      _id: ID,

      organization: organization,
      job: job,
      professional: pro,
      state: data.state,
      interviewFeedback: data.interviewFeedback,
      interviewLengthMinutes: data.interviewLengthMinutes,

      assignedCid: data.assignedCid,

      __v: 0,
    };

    dispatch(updateJobApplication({ payload: payload, id: ID })).then(() => {
      navigate("/jobs/application");
    });
  };
  const handleReset = () => {
    reset();
  };

  const handleCreatedByChange = (e) => {
    setValue("createdBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleUpdatedByChange = (e) => {
    setValue("updatedBy", e.target.value);
    let userID;
    //Searching for the name in the Users array to find the ID
    userOptions.map((obj) => {
      if (obj.value == e.target.value) {
        userID = obj.key;
      }
    });
  };

  const [updated1, setUpdated1] = useState(false);
  const [updated2, setUpdated2] = useState(false);
  const [updated3, setUpdated3] = useState(false);
  const [updated4, setUpdated4] = useState(false);
  const [updated5, setUpdated5] = useState(false);
  const [updated6, setUpdated6] = useState(false);
  const [updated7, setUpdated7] = useState(false);

  const [appliedd, setApplied] = useState(null);
  const [interviewAt, setInterviewAt] = useState(null);
  const [approvedAt, setApprovedAt] = useState(null);
  const [testRequest, setTestRequest] = useState(null);
  const [interviewRequest, setInterviewRequest] = useState(null);
  useEffect(() => {
    let createdDate = getValues("createdAt");
    let updatedDate = getValues("updatedAt");
    let applied = getValues("appliedAt");

    if (createdDate == "Invalid date") {
      setValue("createdAt", "");
    }
    if (updatedDate == "Invalid date") {
      setValue("updatedAt", "");
    }
    let interviewAt = getValues("interviewStart");
    let interviewRequest = getValues("interviewRequestAt");

    let approved = getValues("approvedAt");
    let test = getValues("testRequestAt");

    if (test?.ts == "") setTestRequest(null);
    if (test != undefined) {
      if (!updated5) {
        setTestRequest(new Date(parseInt(test.ts)).toUTCString() + "+530");

        setUpdated5(true);
      }
    }
    if (approved?.ts == "") setApprovedAt(null);
    if (approved != undefined) {
      if (!updated6) {
        setApprovedAt(new Date(parseInt(approved.ts)).toUTCString() + "+530");

        setUpdated6(true);
      }
    }

    if (interviewRequest?.ts == "") setInterviewRequest(null);
    if (interviewRequest != undefined) {
      if (!updated6) {
        setInterviewRequest(
          new Date(parseInt(interviewRequest.ts)).toUTCString() + "+530"
          // new Intl.DateTimeFormat("en-GB").format(new Date(parseInt(interviewRequest.ts)))
        );

        setUpdated7(true);
      }
    }

    if (applied?.ts == "") setApplied(null);

    if (applied != undefined) {
      if (!updated3) {
        const res = applied.ts;
        setApplied(new Date(parseInt(res)).toUTCString() + "+530");

        setUpdated3(true);
      }
    }
    if (interviewAt?.ts == "") setInterviewAt(null);
    if (interviewAt != undefined) {
      if (!updated4) {
        setInterviewAt(
          new Date(parseInt(interviewAt.ts)).toUTCString() + "+530"
        );

        setUpdated4(true);
      }
    }

    if (createdDate != undefined && createdDate !== "") {
      if (!updated1) {
        const a = Moment(createdDate).format("DD/MM/YYYY");

        setValue("createdAt", a);

        setUpdated1(true);
      }
    }

    if (updatedDate != undefined && updatedDate !== "") {
      if (!updated2) {
        const a = Moment(updatedDate).format("DD/MM/YYYY");
        setValue("updatedAt", a);

        setUpdated2(true);
      }
    }
  }, [
    getValues("updatedAt"),
    getValues("interviewRequestAt"),
    getValues("approvedAt"),
    getValues("interviewStart"),
    getValues("createdAt"),
    getValues("appliedAt"),
    getValues("testRequestAt"),
  ]);

  // console.log("approvedAt>>", approvedAt)
  //approveAt
  let approvedATIST = getValues("approvedAt");
  console.log("appliedAt>>",approvedATIST?.ts)
  const formatISTDateTime = (timestamp) => {
    if (!timestamp) {
      return "NA";
    }
  
    const options = {
      timeZone: 'Asia/Kolkata', // Set the time zone to IST
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Use 24-hour format
    };
  
    const ISTDateTime = new Date(parseInt(timestamp, 10)).toLocaleString('en-IN', options);
    return ISTDateTime;
  };
  const formattedDateTime = formatISTDateTime(approvedATIST?.ts);
  const [datePart, timePart] = formattedDateTime.split(', ');
  const [day, month, year] = datePart?.split('/').map(part => part.trim());
  const formattedDate1 = `${year}/${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}`;
  const aprovedTimeAndDateIST = `${formattedDate1} ${timePart}`
  // console.log("4-->",aprovedTimeAndDateIST);

  //appliedAt
  let appliedATIST = getValues("appliedAt");
  // console.log("appliedAt>>",appliedATIST?.ts)
  const formatISTDateTime2 = (timestamp) => {
    if (!timestamp) {
      return "NA";
    }
  
    const options = {
      timeZone: 'Asia/Kolkata', // Set the time zone to IST
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true, // Use 24-hour format
    };
  
    const ISTDateTime1 = new Date(parseInt(timestamp, 10)).toLocaleString('en-IN', options);
    return ISTDateTime1;
  };
  const formattedDateTime2 = formatISTDateTime2(appliedATIST?.ts);
  const [datePart2, timePart2] = formattedDateTime2.split(', ');
  const [day2, month2, year2] = datePart2?.split('/').map(part => part.trim());
  const formattedDate2 = `${year2}/${month2 < 10 ? "0" + month2 : month2}/${day2 < 10 ? "0" + day2 : day2}`;
  const aprovedTimeAndDateIST2 = `${formattedDate2} ${timePart2}`
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {formValues._id ? (
        <MDBox margin={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>
          </Grid>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginTop: "1.5rem",
                            fontWeight: 400,
                            color: "gray",
                          }}
                        >
                          ID : {ID}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Organization
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={organizationOptions}
                          placeholder="Organization"
                          control={control}
                          getValues={getValues}
                          defaultValue="none"
                          name="organization.name"
                          customOnChange={handleOrganizationChange}
                          // customOnChange={(e) =>
                          //   setValue("organization.name", e.target.value)
                          // }
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Job
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Id"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          value={responseData[0]?.job?.name}
                        />
                      </Grid>
                      {/* <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={jobOptions}
                          placeholder="Job"
                          control={control}
                          getValues={getValues}
                          defaultValue="none"
                          name="job.name"
                          customOnChange={handleJobChange}
                        />
                      </Grid> */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Professional
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={userOptions}
                          placeholder="Professional"
                          control={control}
                          getValues={getValues}
                          defaultValue="none"
                          name="professional.name.full"
                          customOnChange={handleProfessionalChange}
                        />
                      </Grid>

                      {/* Mobile Number */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Mobile Number
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Id"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          // {...register("jobId")}
                          // value={responseData[0]?.professional?.phoneNumbers[0]?.number || "NA"}
                          value={responseData[0]?.professional?.phoneNumbers ? responseData[0]?.professional?.phoneNumbers[0]?.number : "NA"}
                        />
                      </Grid>


                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography variant="subtitle1">
                          Registration State
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          disabled={true}
                          fullWidth
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("professional.registrationState")}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Applied At Date and Time
                        </Typography>
                      </Grid>

                      <Grid
                        xs={7}
                        sx={{
                          marginTop: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={6}>
                          <Controller
                            name={"appliedAt"}
                            control={control}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  {...field}
                                  // value={appliedd}
                                  value={aprovedTimeAndDateIST2}
                                  onChange={(newValue) => {
                                    setApplied(newValue.$d);

                                    const a = `${newValue.$d}`;
                                    const b = a.toString().split("+")[0];
                                    setValue(
                                      "appliedAt",
                                      new Date(b).toISOString()
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          State
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <SelectDropDownNew
                          options={selectOptions}
                          control={control}
                          getValues={getValues}
                          defaultValue="none"
                          name="state"
                          customOnChange={(e) =>
                            setValue("state", e.target.value)
                          }
                          // {...register("state")}
                        />
                      </Grid>

                       {/* cancelled Reason */}
                       <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Cancel Reason 
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          placeholder="Job Id"
                          sx={{ width: "100%", marginTop: "2rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          value={responseData[0]?.rejectReason ? responseData[0]?.rejectReason : "NA"}
                        />    
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Interview Feedback
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("interviewFeedback")}
                        />
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Interview At Date & Time
                        </Typography>
                      </Grid>
                      <Grid
                        xs={7}
                        sx={{
                          marginTop: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={6}>
                          <Controller
                            {...register("interviewStart")}
                            name={"interviewStart"}
                            control={control}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  {...field}
                                  value={interviewAt}
                                  onChange={(newValue) => {
                                    const a = `${newValue.$d}`;
                                    const b = a.toString().split("+")[0];
                                    setInterviewAt(newValue.$d);
                                    setValue(
                                      "interviewStart",
                                      new Date(b).toISOString()
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Interview Length Minutes
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("interviewLengthMinutes")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Approved At Date & Time
                        </Typography>
                      </Grid>
                      <Grid
                        xs={7}
                        sx={{
                          marginTop: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={6}>
                          <Controller
                            name={"approvedAt"}
                            control={control}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  {...field}
                                  // inputFormat="DD/MM/YYYY"
                                  // value={approvedAt}
                                  value={aprovedTimeAndDateIST}
                                  onChange={(newValue) => {
                                    const a = `${newValue.$d}`;
                                    const b = a.toString().split("+")[0];
                                    setApprovedAt(newValue.$d);
                                    setValue(
                                      "approvedAt",
                                      new Date(b).toISOString()
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Test Request Date & Time
                        </Typography>
                      </Grid>
                      <Grid
                        xs={7}
                        sx={{
                          marginTop: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={6}>
                          <Controller
                            name={"testRequestAt"}
                            control={control}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  {...field}
                                  // inputFormat="DD/MM/YYYY"
                                  value={testRequest}
                                  onChange={(newValue) => {
                                    const a = `${newValue.$d}`;
                                    const b = a.toString().split("+")[0];
                                    setTestRequest(newValue.$d);
                                    setValue(
                                      "testRequestAt",
                                      new Date(b).toISOString()
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "80%", marginTop: "0.8rem" }}
                        >
                          Interview Request Date & Time
                        </Typography>
                      </Grid>
                      <Grid
                        xs={7}
                        sx={{
                          marginTop: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item xs={6}>
                          <Controller
                            name={"interviewRequestAt"}
                            control={control}
                            render={({ field }) => (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  {...field}
                                  // inputFormat="DD/MM/YYYY"
                                  value={interviewRequest}
                                  onChange={(newValue) => {
                                    const a = `${newValue.$d}`;
                                    const b = a.toString().split("+")[0];
                                    setInterviewRequest(newValue.$d);
                                    setValue(
                                      "interviewRequestAt",
                                      new Date(b).toISOString()
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      onKeyDown={onKeyDown}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Assigned Cid
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("assignedCid")}
                        />
                      </Grid>

                      {/* created and updated timestamps */}
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Created At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("createdAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "70%", marginTop: "0.8rem" }}
                        >
                          Updated At
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          disabled
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          {...register("updatedAt")}
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Created By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          fullWidth
                          {...register("createdBy.name.full")}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "2rem" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ width: "50%", marginTop: "0.8rem" }}
                        >
                          Updated By
                        </Typography>
                      </Grid>
                      <Grid xs={7} sx={{ marginTop: "2rem" }}>
                        <MDInput
                          fullWidth
                          {...register("updatedBy.name.full")}
                          placeholder=""
                          sx={{ width: "100%", marginTop: "0.4rem" }}
                          inputProps={{
                            style: { fontSize: 15, fontWeight: "bold" },
                          }}
                          disabled
                        />
                      </Grid>

                      <Grid xs={12} md={12}>
                        <Divider sx={{ width: "100%" }} />
                      </Grid>

                      <div style={{ marginTop: "2rem" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ color: "#fff" }}
                        >
                          {" "}
                          Submit
                        </Button>

                        {/* <Button
                          variant="outlined"
                          sx={{ color: "#111", marginLeft: "1rem" }}
                        >
                          Reset
                        </Button> */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </form>
          </DialogContent>
        </MDBox>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24%",
            marginTop: "20%",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </DashboardLayout>
  );
}

export default ApplicationDetailsById;
