import { CircularProgress } from "@material-ui/core";
import { DataGrid, GridToolbar, GridPagination } from "@mui/x-data-grid";
import {
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";
import FilterBar from "examples/FilterBar/FilterBar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  getPremiumUsersList,
  getFilteredDocsDetails,
} from "./Redux/actionCreator";

import {
  pageChanged,
  pageLimit,
  searchKeyword,
  updateStartDate,
  updateEndDate,
  updateFilterProfessionalType,
  updateFilterPastPremium,
  updateFilterPresentPremium,
  updateFilterByStatus
} from "./Redux/reducer";
import CloseIcon from "@material-ui/icons/Close";
import { getPremiumUserByIdService } from "services/userServices";
import { setProfessionType } from "layouts/events/Redux/actionCreator";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

// const label = { inputProps: { "aria-label": "Checkbox demo" } };
const PremiumUser = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState("");
  const [manualValue, setManualValue] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [docsStateValue, setDocsStateValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");

  function getCreatedAT(params) {
    var a;
    const rowName = params?.row?.createdAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  function getUpdatedAT(params) {
    var a;
    const rowName = params?.row?.updatedAt;
    if (rowName) {
      a = moment(rowName).format("YYYY/MM/DD");
      return `${a}`;
    } else {
      a = "NA";
      return `${a}`;
    }
  }

  // const [openPopup, setOpenPopup] = useState(false);
  // const [selectedJobCount, setSelectedJobCount] = useState(null);

  // const handleJobCountClick = async (sId) => {
  //   try {
  //     const sDetails = await getPremiumUserByIdService(sId); // Fetch user details by ID
  //     setSelectedJobCount(sDetails); // Set the selected user details
  //     setOpenPopup(true); // Open the popup dialog
  //   } catch (error) {
  //     console.error("Error fetching user details:", error);
  //   }
  // };

  // const handleClosePopup = () => {
  //   setOpenPopup(false);
  // };

  // console.log("sDetails>>", selectedJobCount);
  // // Assuming selectedJobCount is an array of objects
  // const renderJobCountPopup = () => {
  //   // Add a unique id to each row if it doesn't already exist
  //   const rowsWithIds = selectedJobCount?.map((row, index) => ({
  //     ...row,
  //     id: index + 1, // Example: Use index + 1 as the id
  //   }));

  //   return (
  //     <Dialog open={openPopup} onClose={handleClosePopup}>
  //       <DialogTitle>
  //         ShiftLogs Count Details
  //         <IconButton
  //           aria-label="close"
  //           onClick={handleClosePopup}
  //           style={{
  //             position: "absolute",
  //             marginLeft: "800px",
  //             marginTop: "-5px",
  //           }}
  //         >
  //           <CloseIcon />
  //         </IconButton>
  //       </DialogTitle>
  //       <DialogContent style={{ width: "1100px", height: "800px" }}>
  //         {selectedJobCount && (
  //           <>
  //             {selectedJobCount.map((job) => (
  //               <div key={job.jobid}>
  //                 {job.jobDetails.map((jobDetail, index) => (
  //                   <div key={index}>
  //                     {jobDetail.shiftLogs.length > 0 ? (
  //                       <DataGrid
  //                         rows={jobDetail.shiftLogs.map((shiftLog) => ({
  //                           ...shiftLog, // Make sure shiftLog contains shiftId
  //                           id: shiftLog._id, // Use shiftLog._id as the custom ID
  //                           shiftId: shiftLog._id,
  //                         }))}
  //                         columns={[
  //                           {
  //                             field: "shiftId",
  //                             headerName: `Shift ID`,
  //                             renderCell: (cellValues) => {
  //                               const rowName = cellValues?.row?.shiftId;
  //                               return rowName ? rowName : "NA";
  //                             },
  //                             flex: 1,
  //                           },
  //                           // Add more columns as needed
  //                         ]}
  //                         pageSize={5}
  //                       />
  //                     ) : (
  //                       <p>No shift logs found for this job detail.</p>
  //                     )}
  //                   </div>
  //                 ))}
  //               </div>
  //             ))}
  //           </>
  //         )}
  //       </DialogContent>
  //     </Dialog>
  //   );

  //   // return (
  //   //   <Dialog open={openPopup} onClose={handleClosePopup}>
  //   //     <DialogTitle>
  //   //       ShiftLogs Count Details
  //   //       <IconButton
  //   //         aria-label="close"
  //   //         onClick={handleClosePopup}
  //   //         style={{
  //   //           position: "absolute",
  //   //           marginLeft: "800px",
  //   //           marginTop: "-5px",
  //   //         }}
  //   //       >
  //   //         <CloseIcon />
  //   //       </IconButton>
  //   //     </DialogTitle>
  //   //     <DialogContent style={{ width: "1100px", height: "800px" }}>
  //   //       {selectedJobCount && (
  //   //         <DataGrid
  //   //           rows={rowsWithIds} // Use rows with unique ids
  //   //           columns={[
  //   //             {
  //   //               field: "jobid",
  //   //               headerName: `Job ID`,
  //   //               renderCell: (cellValues) => {
  //   //                 // console.log("1---->",cellValues)
  //   //                 var a = "NA";
  //   //                 var rowName = cellValues?.row?.jobDetails;
  //   //                 // console.log("rowName-->", rowName);
  //   //                 if (
  //   //                   rowName &&
  //   //                   Array.isArray(rowName) &&
  //   //                   rowName.length > 0
  //   //                 ) {
  //   //                   //   console.log("2-->", rowName[0]);
  //   //                   a = rowName[0]?.code;
  //   //                   return a;
  //   //                 }
  //   //               },
  //   //               flex: 1,
  //   //             },
  //   //             {
  //   //               field: "subscriptionId",
  //   //               headerName: `Subscription ID`,
  //   //               renderCell: (cellValues) => {
  //   //                 // console.log("1---->",cellValues)
  //   //                 var a;
  //   //                 const rowName = cellValues?.row?.subscription_id;
  //   //                 if (rowName) {
  //   //                   a = rowName;
  //   //                   return `${a}`;
  //   //                 } else {
  //   //                   a = "NA";
  //   //                   return `${a}`;
  //   //                 }
  //   //                 // return rwoName;
  //   //               },
  //   //               flex: 1,
  //   //             },
  //   //             {
  //   //               field: "currentStart",
  //   //               headerName: `Subscription Start Date`,
  //   //               renderCell: (cellValues) => {
  //   //                 // console.log("1---->",cellValues)
  //   //                 var a;

  //   //                 const rowName = cellValues?.row?.current_start;
  //   //                 if (rowName) {
  //   //                   a = moment(rowName).format("YYYY/MM/DD");
  //   //                   return `${a}`;
  //   //                 } else {
  //   //                   a = "NA";
  //   //                   return `${a}`;
  //   //                 }
  //   //                 // return rwoName;
  //   //               },
  //   //               flex: 1,
  //   //             },
  //   //             {
  //   //               field: "currentEnd",
  //   //               headerName: `Subscription End Date`,
  //   //               renderCell: (cellValues) => {
  //   //                 // console.log("1---->",cellValues)
  //   //                 var a;

  //   //                 const rowName = cellValues?.row?.current_end;
  //   //                 if (rowName) {
  //   //                   a = moment(rowName).format("YYYY/MM/DD");
  //   //                   return `${a}`;
  //   //                 } else {
  //   //                   a = "NA";
  //   //                   return `${a}`;
  //   //                 }
  //   //                 // return rwoName;
  //   //               },
  //   //               flex: 1,
  //   //             },
  //   //             {
  //   //               field: "shiftId",
  //   //               headerName: `Shift ID`,
  //   //               renderCell: (cellValues) => {
  //   //                 const jobDetails = cellValues?.row?.jobDetails;
  //   //                 // console.log(">>>>>", jobDetails);

  //   //                 if (jobDetails && Array.isArray(jobDetails)) {
  //   //                   const premiumShiftlogsIds = jobDetails
  //   //                     .flatMap((job) => job?.shiftLogs)
  //   //                     .filter((shiftlog) => shiftlog?.isPremium === true)
  //   //                     .map((shiftlog) => shiftlog?._id);

  //   //                   // console.log("1-->", premiumShiftlogsIds);
  //   //                   if (premiumShiftlogsIds?.length > 0) {
  //   //                     const height = Math.min(
  //   //                       premiumShiftlogsIds.length * 20,
  //   //                       200
  //   //                     ); // Assuming each record takes 20px height
  //   //                     const width = Math.max(
  //   //                       ...premiumShiftlogsIds.map((id) => id.length * 10),
  //   //                       100
  //   //                     );

  //   //                     return (
  //   //                       <div
  //   //                         style={{
  //   //                           display: "flex",
  //   //                           flexDirection: "column",
  //   //                           height: `${height}px`,
  //   //                           width: `${width}px`,
  //   //                           overflowX: "auto",
  //   //                         }}
  //   //                       >
  //   //                         {premiumShiftlogsIds.map((id) => (
  //   //                           <div key={id}>{id}</div>
  //   //                         ))}
  //   //                       </div>
  //   //                     );
  //   //                   } else {
  //   //                     return "NA";
  //   //                   }
  //   //                 } else {
  //   //                   return "NA";
  //   //                 }
  //   //               },
  //   //               flex: 1.5,
  //   //             },
  //   //           ]}
  //   //           pageSize={5}
  //   //         />
  //   //       )}
  //   //     </DialogContent>
  //   //   </Dialog>
  //   // );
  // };

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedJobCount, setSelectedJobCount] = useState(null);

  const handleJobCountClick = async (sId) => {
    try {
      const sDetails = await getPremiumUserByIdService(sId);
      setSelectedJobCount(sDetails);
      setOpenPopup(true);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  // console.log("sDetails>>", selectedJobCount);

  const renderJobCountPopup = () => {
    function formatDate() {
      const now = new Date();

      const day = String(now.getDate()).padStart(2, "0");

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const month = monthNames[now.getMonth()];
      const year = now.getFullYear();

      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

      return formattedDate;
    }

    // function formatDate(date) {
    //   var a;
    //   console.log("date>>", date)
    //   const rowName = date;
    //   if (rowName) {
    //     a = moment(rowName).format("YYYY/MM/DD");
    //     console.log("a-->", a)
    //     return `${a}`;
    //   } else {
    //     a = "NA";
    //     return `${a}`;
    //   }
    // }

    const handleExportCSV = () => {
      const flattenedData = flattenData(selectedJobCount);
      let csvContent = "data:text/csv;charset=utf-8,";
      const headerRow = Object.keys(flattenedData[0]).filter(
        (key) => key !== "id"
      );
      csvContent += headerRow.join(",") + "\n";
      flattenedData.forEach((row) => {
        const rowData = Object.values(row).filter(
          (value, index) => index !== 0
        );
        // const rowData = Object.values(row).slice(1).map((value, index) => {
        //   if (index === 3 || index === 4) {
        //     return formatDate(value);
        //   }
        //   return value;
        // });
        // console.log("rowdData", rowData)
        csvContent += rowData.join(",") + "\n";
      });
      // console.log("csvContent>>", csvContent)

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `ShiftLogs-${formatDate()}.csv`);
      document.body.appendChild(link);
      link.click();
    };

    if (!selectedJobCount || !Array.isArray(selectedJobCount)) {
      return null;
    }
    return (
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        //  fullScreen
        maxWidth="xl"
      >
        <DialogTitle>
          ShiftLogs Count Details
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            style={{
              position: "absolute",
              marginLeft: "820px",
              marginTop: "-5px",
            }}
            // style={{
            //   position: "absolute",
            //   right: "10px",
            //   top: "10px",
            // }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Button
          onClick={handleExportCSV}
          variant="contained"
          color="primary"
          style={{ marginLeft: "1020px", width: "10px" }}
          // style={{ position: "absolute", right: "10px", top: "50px" }}
        >
          Export
        </Button>
        <DialogContent style={{ width: "1100px", height: "800px" }}>
          {selectedJobCount && (
            <DataGrid
              rows={flattenData(selectedJobCount)}
              columns={[
                { field: "shiftId", headerName: `Shift ID`, flex: 1.5 },
                { field: "jobid", headerName: `Job ID`, flex: 1 },
                {
                  field: "subscriptionId",
                  headerName: `Subscription ID`,
                  flex: 1,
                },
                {
                  field: "currentStart",
                  headerName: `Subscription Start Date`,
                  flex: 1,
                },
                {
                  field: "currentEnd",
                  headerName: `Subscription End Date`,
                  flex: 1,
                },
                // { field: "shiftId", headerName: `Shift ID`, flex: 1.5 },
              ]}
              // pageSize={5}
              // getRowId={(row) => row.id}
              pagination
              pageSize={5}
              getRowId={(row) => row.id}
              components={{
                Pagination: GridPagination,
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  };

  const flattenData = (data) => {
    const flattenedRows = [];

    data.forEach((job, index) => {
      job.jobDetails.forEach((detail, subIndex) => {
        const shiftIds = getShiftIds(detail);
        if (Array.isArray(shiftIds)) {
          shiftIds.forEach((shiftId, shiftIndex) => {
            const uniqueId = `${index}-${subIndex}-${shiftIndex}`;
            const newRow = {
              id: uniqueId, // Add unique ID
              shiftId: shiftId,
              jobid: detail.code || "NA",
              subscriptionId: job.subscription_id || "NA",
              currentStart: job.current_start
                ? moment(job.current_start).format("YYYY/MM/DD")
                : "NA",
              currentEnd: job.current_end
                ? moment(job.current_end).format("YYYY/MM/DD")
                : "NA",
            };
            flattenedRows.push(newRow);
          });
        } else {
          // If shiftIds is not an array, treat it as a single value
          const uniqueId = `${index}-${subIndex}`;
          const newRow = {
            id: uniqueId, // Add unique ID
            shiftId: shiftIds,
            jobid: detail.code || "NA",
            subscriptionId: job.subscription_id || "NA",
            currentStart: job.current_start
              ? moment(job.current_start).format("YYYY/MM/DD")
              : "NA",
            currentEnd: job.current_end
              ? moment(job.current_end).format("YYYY/MM/DD")
              : "NA",
          };
          flattenedRows.push(newRow);
        }
      });
    });

    return flattenedRows;
  };

  const getShiftIds = (detail) => {
    if (detail.shiftLogs && typeof detail.shiftLogs === "object") {
      // Extract _id property from shiftLogs object
      const shiftId = detail.shiftLogs._id;
      return [shiftId];
    } else if (detail.shiftLogs && Array.isArray(detail.shiftLogs)) {
      // If shiftLogs is an array, map _id from each item
      return detail.shiftLogs.map((shiftLog) => shiftLog._id);
    } else {
      return ["NA"]; // Default value when shiftLogs doesn't exist or is empty
    }
  };

  const selectStatusOptions = [
    {
      key: "created",
      value: "created",
    },
    {
      key: "active",
      value: "active",
    },
    {
      key: "Inactive",
      value: "Inactive",
    },
    {
      key: "cancelled",
      value: "cancelled",
    },
  ];

  // const flattenData = (data) => {
  //   const flattenedRows = [];

  //   data.forEach((job, index) => {
  //     job.jobDetails.forEach((detail, subIndex) => {
  //       const shiftIds = getShiftIds(detail);
  //       shiftIds?.forEach((shiftId, shiftIndex) => {
  //         const uniqueId = `${index}-${subIndex}-${shiftIndex}`;
  //         const newRow = {
  //           id: uniqueId, // Add unique ID
  //           shiftId: shiftId,
  //           jobid: detail.code || "NA",
  //           subscriptionId: job.subscription_id || "NA",
  //           currentStart: job.current_start
  //             ? moment(job.current_start).format("YYYY/MM/DD")
  //             : "NA",
  //           currentEnd: job.current_end
  //             ? moment(job.current_end).format("YYYY/MM/DD")
  //             : "NA",
  //         };
  //         flattenedRows.push(newRow);
  //       });
  //     });
  //   });

  //   return flattenedRows;
  // };

  // const getShiftIds = (detail) => {
  //   if (detail.shiftLogs && detail.shiftLogs.length > 0) {
  //     return detail.shiftLogs.map((shiftLog) => shiftLog._id);
  //   } else {
  //     return "NA";
  //   }
  // };

  const columns = [
    {
      field: "_id",
      headerName: `User ID`,
      renderCell: (cellValues) => {
        // console.log("cell>>>>>", cellValues);
        // const Id = cellValues?.row?.userDetails?._id;
        // return Id;
        const rowName = cellValues?.row?.userDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          // console.log("2-->", rowName[0]);
          const rowId = rowName[0]._id;
          return rowId;
        }
      },
      // flex: 1,
      // valueGetter: getPermApplicationId,
    },
    {
      field: "name",
      headerName: `User Name`,
      renderCell: (cellValues) => {
        var a = "NA";
        var rowName = cellValues?.row?.userDetails;
        // console.log("rowName-->", rowName);
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          a = rowName[0]?.name?.full;
          const rowId = rowName[0]._id;
          return <Link to={`/users/name/${rowId}`}>{a}</Link>;
          //   return a;
        }
      },
      

      valueGetter: (cellValues) => {
        var a = "NA";
        var rowName = cellValues?.row?.userDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          a = rowName[0]?.name?.full;
          return a;
        }
      },
      // flex: 1.5,
    },

    {
      field: "profilePan",
      headerName: `Pan Number`,
      renderCell: (cellValues) => {
        var a = "NA";
        var rowName = cellValues?.row?.userDetails;
        // console.log("rowName-->", rowName);
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          a = rowName[0]?.profile?.pan;
            return a;
        }
      },
      // flex: 1.5,
    },
    {
      field: "start_at",
      headerName: `PPP Start date`,
      renderCell: (cellValues) => {
        var a;
        // const rowName = cellValues?.row?.start_at;
        const rowName = cellValues?.row?.current_start;
        // console.log("1-->", rowName);
        // const finalName = rowName + '000'
        // console.log("2-->", finalName);
        if (rowName) {
          // a = moment(rowName).format("YYYY/MM/DD");
          // return `${a}`;
          // a = moment.utc(rowName).utcOffset("+05:30").format("YYYY/MM/DD HH:mm:ss");
          // return `${a} IST`;
          a = moment.utc(rowName).utcOffset("+05:30").format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      // flex: 1.5,

      // valueGetter: getCreatedAT,
    },
    {
      field: "end_at",
      headerName: `PPP End date`,
      renderCell: (cellValues) => {
        var a;
        // const rowName = cellValues?.row?.end_at;
        const rowName = cellValues?.row?.current_end;
        if (rowName) {
          // a = moment(rowName).format("YYYY/MM/DD");
          // return `${a}`;
          a = moment.utc(rowName).utcOffset("+05:30").format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      // flex: 1.5,

      // valueGetter: getCreatedAT,
    },
    {
      field: "planDetails",
      headerName: `Plan Name`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.planDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          a = rowName[0]?.item?.name;
          // return a;
          return (
            <Tooltip title={a} arrow>
              <div>{a}</div>
            </Tooltip>
          );
        }
      },
      // valueGetter: (cellValues) => cellValues?.row?.document?.documentType,
      // flex: 1.5,
    },
    {
      field: "professionalType",
      headerName: `Profession Type`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.userDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          //   console.log("2-->", rowName[0]);
          const professional = rowName[0]?.professionalType;
          if (
            professional &&
            Array.isArray(professional) &&
            professional.length > 0
          ) {
            // console.log("3-->", number[0]);
            a = professional[0].name;
          }
        }
        return a;
      },
      // valueGetter: (cellValues) => cellValues?.row?.document?.documentUID,
      // flex: 1.5,
    },
    {
      field: "phoneNumbers",
      headerName: `Contact Number`,
      renderCell: (cellValues) => {
        var phoneNumber;
        const rowName = cellValues?.row?.userDetails;
        if (rowName && Array.isArray(rowName) && rowName.length > 0) {
          // console.log("2-->", rowName[0]);
          const number = rowName[0]?.phoneNumbers;
          if (number && Array.isArray(number) && number.length > 0) {
            // console.log("3-->", number[0]);
            phoneNumber = number[0].number;
          }
        }
        return phoneNumber;
      },
      // valueGetter: (cellValues) => cellValues?.row?.document?.requestState,
      // flex: 1.5,
    },

    {
      field: "CountsOFJobs",
      headerName: `Count Of Shiftlogs`,
      renderCell: (cellValues) => {
        // console.log(">>", cellValues?.row?.jobCount);
        const jobCount = cellValues?.row?.totalJobCount || 0;
        let allSubsUser = premiumUsers;
        // const userId = cellValues?.row?.userDetails[0]?._id;
        const sId = cellValues?.row?._id;
        return (
          <div
            style={{
              // cursor: "pointer",
              cursor: jobCount ? "pointer" : "default",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              textAlign: "center",
              textDecoration: jobCount ? "underline" : "none",
            }}
            // onClick={() => handleJobCountClick(allSubsUser)}
            onClick={() => handleJobCountClick(sId)}
          >
            {jobCount}
          </div>
        );
        // let a;
        // const rowName = cellValues?.row?.jobCount;
        // // console.log(">>>>>>", rowName)
        // if (rowName) {
        //   a = rowName;
        //   return `${a}`;
        // } else {
        //   a = "0";
        //   return `${a}`;
        // }
        // return rowName;

        // let count;
        // count = cellValues?.row?.jobCount;
        // if(count){
        //   // const rowName = cellValues?.row?.userDetails;
        //   // if (rowName && Array.isArray(rowName) && rowName.length > 0) {
        //   //   // console.log("2-->", rowName[0]);
        //   //   const rowId = rowName[0]._id;
        //     const id = cellValues?.row?._id;
        //     return <Link to={`/users/premiumuser/${id}`}>{count}</Link>;
        //   // }
        // }else{
        //   count = "0";
        //   return `${count}`;
        // }
      },
      // flex: 1.5,
    },
    {
      field: "status",
      headerName: `Subscription Status`,
      renderCell: (cellValues) => {
        // console.log(cellValues);
        var a;
        const rowName = cellValues?.row?.status;
        if (rowName) {
          a = rowName;
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      // flex: 1.5,
    },
    {
      field: "subsInvoiceDetails",
      headerName: `Premium Invoice`,
      renderCell: (cellValues) => {
        var a;
        const rowName = cellValues?.row?.subsInvoiceDetails;
        // console.log(">>",rowName);
        const activeStatus = cellValues?.row?.status;
        // console.log("activeStatus>>",activeStatus)
        // if (activeStatus === "active") {
          if (rowName && Array.isArray(rowName) && rowName.length > 0) {
            a = rowName[0]?.url;
            // console.log(">>", a)
            // return a;
            const fileName = "PremiumInvoices.pdf";
            return (
              <Tooltip title={`${a}`} arrow>
                <a href={`${a}`} download={fileName}>
                  {a}
                </a>
              </Tooltip>
            );
          // }
        } else {
          a = "NA";
          return `${a}`;
        }
      },
      // valueGetter: (cellValues) => cellValues?.row?.document?.documentType,
      // flex: 2,
    },
    {
      field: "eventName",
      headerName: `Event Name`,
      renderCell: (cellValues) => {
        var a;
        // const rowName = cellValues?.row?.createdAt;
        const rowName = cellValues?.row?.eventName;
        // console.log("1-->", rowName)
        if (rowName) {
          a = rowName;
          return (
            <Tooltip title={a} arrow>
              <div>{a}</div>
            </Tooltip>
          );
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      // flex: 1.5,
    },
    {
      field: "createdAt",
      headerName: `Created Date`,
      renderCell: (cellValues) => {
        var a;
        // const rowName = cellValues?.row?.createdAt;
        const rowName = cellValues?.row?.created_at;
        // console.log("1-->", rowName)
        if (rowName) {
          // a = moment(rowName).format("YYYY/MM/DD");
          // return `${a}`;
          a = moment.utc(rowName).utcOffset("+05:30").format("YYYY/MM/DD");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
      type: "date",
      // flex: 1.5,

      valueGetter: getCreatedAT,
    },

    {
      field: "createdTime",
      headerName: `Created Time`,
      renderCell: (cellValues) => {
        var a;
        // const rowName = cellValues?.row?.createdAt;
        const rowName = cellValues?.row?.created_at;
        // console.log("1-->", rowName)
        if (rowName) {
          // const dt   = moment(rowName).format("YYYY/MM/DD HH:mm:ss");
          // console.log("dt", dt)
          // return `${a}`;
          a = moment.utc(rowName).utcOffset("+05:30").format("HH:mm:ss");
          return `${a}`;
        } else {
          a = "NA";
          return `${a}`;
        }
        // const created = moment(cellValues?.row?.createdAt).format("YYYY/MM/DD");

        // return created;
      },
    },

    // {
    //   field: "updatedAt",
    //   headerName: `Updated At`,
    //   renderCell: (cellValues) => {
    //     var a;
    //     const rowName = cellValues?.row?.updatedAt;
    //     if (rowName) {
    //       a = moment(rowName).format("YYYY/MM/DD");
    //       return `${a}`;
    //     } else {
    //       a = "NA";
    //       return `${a}`;
    //     }
    //     // const updated = moment(cellValues?.row?.updatedAt).format("YYYY/MM/DD");

    //     // return updated;
    //   },
    //   type: "date",
    //   valueGetter: getUpdatedAT,
    //   // flex: 1.5,
    // },
  ];

  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (state?.data) {
      setisOpen(true);
    }
  }, [state]);

  const [loading, setLoading] = useState(false);
  const [candidateValue, setCandidateValue] = useState("");

  const professionOptions = [];

  const { Profession_Type_Data } = useSelector((state) => state.events);

  const professionData = Profession_Type_Data?.professionInfo;
  professionData.map((ele) => {
    let obj = {
      key: ele._id,
      value: ele.name,
    };

    professionOptions.push(obj);
  });

  const { Premium_Users_Data, Premium_Filtered_Users_Data } = useSelector(
    (state) => state.user
  );
  // console.log("1382-->", Users_Data)
  const premiumUsers = Premium_Users_Data?.Premium_Users_DataInfo;

  const premiumFilteredUsers =
    Premium_Filtered_Users_Data?.Premium_Filtered_Users_DataInfo;

  // console.log("1-->", premiumUsers);

  //   console.log("DocVerifaction_List_Data", DocVerifaction_List_Data);
  const totalCount = Premium_Users_Data?.total;
  const currentPage = useSelector((state) => state.user.currentPage);
  const pageSize = useSelector((state) => state.user.pageSize);
  const searchKey = useSelector((state) => state.user.searchKey);
  const filterProfessionalType = useSelector(
    (state) => state.user.filterProfessionalType
  );
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);

  const filterPastPremium = useSelector(
    (state) => state.user.filterPastPremium
  );
  const filterPresentPremium = useSelector(
    (state) => state.user.filterPresentPremium
  );
  const filterByStatus = useSelector(
    (state) => state.user.filterByStatus
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(
        getPremiumUsersList({
          currentPage: currentPage,
          pageSize: pageSize,
          searchKey: searchKey,
          startDate: startDate,
          endDate: endDate,
          filterProfessionalType: filterProfessionalType,
          filterPastPremium: filterPastPremium,
          filterPresentPremium: filterPresentPremium,
          filterByStatus: filterByStatus,
        })
      );
      setLoading(false);
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    pageSize,
    searchKey,
    startDate,
    endDate,
    filterProfessionalType,
    filterPastPremium,
    filterPresentPremium,
    filterByStatus
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getFilteredDocsDetails({
  //         searchKey: searchKey,
  //         startDate: startDate,
  //         endDate: endDate,
  //         filterProfessionalType: filterProfessionalType,
  //         filterPastPremium: filterPastPremium,
  //         filterPresentPremium: filterPresentPremium,
  //         filterByStatus: filterByStatus,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   searchKey,
  //   startDate,
  //   endDate,
  //   filterProfessionalType,
  //   filterPastPremium,
  //   filterPresentPremium,
  //   filterByStatus
  // ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(
  //       getPremiumUsersList({
  //         currentPage: currentPage,
  //         pageSize: pageSize,
  //         searchKey: searchKey,
  //         startDate: startDate,
  //         endDate: endDate,
  //         filterProfessionalType: filterProfessionalType,
  //         filterPastPremium: filterPastPremium,
  //         filterPresentPremium: filterPresentPremium,
  //         filterByStatus: filterByStatus,
  //       })
  //     );
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKey,
  //   startDate,
  //   endDate,
  //   filterProfessionalType,
  //   filterPastPremium,
  //   filterPresentPremium,
  //   filterByStatus
  // ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const hasAnyFilter =
  //       // searchKey.trim() !== "" ||
  //       // filterState.trim() !== "" ||
  //       startDate.trim() !== "" || endDate.trim() !== "";

  //     try {
  //       if (hasAnyFilter) {
  //         await dispatch(
  //           getFilteredDocsDetails({
  //             searchKey: searchKey,
  //             startDate: startDate,
  //             endDate: endDate,
  //             filterProfessionalType: filterProfessionalType,
  //             filterPastPremium: filterPastPremium,
  //             filterPresentPremium: filterPresentPremium,
  //             filterByStatus: filterByStatus,
  //           })
  //         );
  //       } else {
  //         // If nothing is specified, you might want to handle this case
  //         await dispatch(
  //           getPremiumUsersList({
  //             currentPage: currentPage,
  //             pageSize: pageSize,
  //             searchKey: searchKey,
  //             startDate: startDate,
  //             endDate: endDate,
  //             filterProfessionalType: filterProfessionalType,
  //             filterPastPremium: filterPastPremium,
  //             filterPresentPremium: filterPresentPremium,
  //             filterByStatus: filterByStatus,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, [
  //   dispatch,
  //   currentPage,
  //   pageSize,
  //   searchKey,
  //   startDate,
  //   endDate,
  //   filterProfessionalType,
  //   filterPastPremium,
  //   filterPresentPremium,
  //   filterByStatus
  // ]);

  useEffect(() => {
    dispatch(setProfessionType());
  }, []);

  function formatDate() {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const month = monthNames[now.getMonth()];
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    const formattedDate = `${day} ${month} ${year}_${hours}_${minutes}_${seconds}`;

    return formattedDate;
  }

  const handleDownloadData = async () => {
      try {
        setLoading(true);
  
        const hasAnyFilter =
          // searchKey.trim() !== "" ||
          // filterState.trim() !== "" ||
          startDate.trim() !== "" || endDate.trim() !== "";
  
        let csvData = ""
        if (hasAnyFilter) {
          // console.log("inside 1")
          const data = await dispatch(getFilteredDocsDetails({
              searchKey: searchKey,
              startDate: startDate,
              endDate: endDate,
              filterProfessionalType: filterProfessionalType,
              filterPastPremium: filterPastPremium,
              filterPresentPremium: filterPresentPremium,
              filterByStatus: filterByStatus,
            })
          )
            
            csvData = data.payload.data
        } else {
          // console.log("inside 2")
          const data = await dispatch(getFilteredDocsDetails({
              searchKey: searchKey,
              startDate: startDate,
              endDate: endDate,
              filterProfessionalType: filterProfessionalType,
              filterPastPremium: filterPastPremium,
              filterPresentPremium: filterPresentPremium,
              filterByStatus: filterByStatus,
            })
          )
          csvData = data.payload.data
        }
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `premiumUser-${formatDate()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
       
    
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
 
     
   
  };

  // const handleDownloadData = async () => {
  //   try {
  //     const response = premiumFilteredUsers;
  //     // console.log("1405-->",response)
  //     const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = `premiumUser-${formatDate()}.csv`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Error during file download:", error);
  //   }
  // };

  const handleResetFilters = () => {
    // Reset filter values to their initial state
    window.location.reload();
    setSelectedOption("");
    setManualValue("");
  };

  const handleStartDateChange = (startDate) => {
    dispatch(updateStartDate(startDate));
  };

  const handleEndDateChange = (endDate) => {
    dispatch(updateEndDate(endDate));
  };

  const handleSearch = () => {
    const sanitizedSearchKey = inputValue.replace(/[+#^&*()$]/g, "");
    // dispatch(searchKeyword(sanitizedSearchKey));
    if (sanitizedSearchKey) {
      dispatch(searchKeyword(sanitizedSearchKey));
    } else {
      dispatch(updateStartDate(inputStartDate));
      dispatch(updateEndDate(inputEndDate));
    }
  };
  // console.log("inputValue>>", inputValue)

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleProfessionTypeFilterChange = (e) => {
    const selectedProfessionType = e.target.value;
    let selectedProfessionalTypeValue;

    professionOptions.forEach((appState) => {
      if (appState.value === selectedProfessionType) {
        selectedProfessionalTypeValue = appState.value;
      }
    });

    setCandidateValue(selectedProfessionType);

    dispatch(updateFilterProfessionalType(selectedProfessionType));
  };

  const handleStatusFilterChange = (e) => {
    const selectedStatus = e.target.value;
    let selectedStatusValue;

    selectStatusOptions.forEach((status) => {
      if (status.value === selectedStatus) {
        selectedStatusValue = status.value;
      }
    });

    setStatusValue(selectedStatus);

    dispatch(updateFilterByStatus(selectedStatus));
  };

  const [selectedJobStatus, setSelectedJobStatus] = useState("");

  const handleJobStatusChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedJobStatus(selectedValue);

    if (selectedValue === "past") {
      dispatch(updateFilterPastPremium(true));
      dispatch(updateFilterPresentPremium(false));
    } else if (selectedValue === "current") {
      dispatch(updateFilterPastPremium(false));
      dispatch(updateFilterPresentPremium(true));
    }
  };

  return (
    <>
      {" "}
      <DashboardLayout>
        {" "}
        <DashboardNavbar />{" "}
        <div style={{ display: "flex", alignItems: "center" }}>
          <FilterBar
            showDialog={false}
            title1="Premium User"
            showisDeleted={false}
          />
          <Button
            onClick={handleResetFilters}
            color="primary"
            style={{
              marginLeft: "20px", // Adjust as needed
              marginTop: "20px",
              // width: "130px",
              // height: "5px",
              padding: "5px 10px",
              // lineHeight: "-5",
            }}
            variant="contained"
            startIcon={<RefreshIcon style={{ fontSize: 50 }} />}
          >
            Reset All
          </Button>
        </div>
        <Select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          displayEmpty
          style={{ marginLeft: "20px", height: "44px" }}
        >
          <MenuItem value="">Choose Filters</MenuItem>
          <MenuItem value="professionalType">Professional Type</MenuItem>
          <MenuItem value="filterByStatus">Status</MenuItem>
        </Select>
        {selectedOption && (
          <>
            {selectedOption === "professionalType" ? (
              <FormControl
                fullWidth
                style={{ marginLeft: "20px", width: "100px", height: "44px" }}
              >
                <InputLabel
                  id="role-select-label"
                  style={{ textAlign: "center" }}
                >
                  Select Professional Type
                </InputLabel>
                <Select
                  labelId="role-select-label"
                  label="professionalType"
                  // value={filterAll.Role || ""}
                  value={candidateValue || ""}
                  onChange={handleProfessionTypeFilterChange}
                  fullWidth
                  // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                  style={{ width: "150px", height: "44px" }}
                >
                  <MenuItem value="">Reset</MenuItem>
                  {professionOptions.map((appState) => (
                    <MenuItem key={appState.value} value={appState.value}>
                      {appState.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : selectedOption === "filterByStatus" ? (
              <FormControl
                fullWidth
                style={{ marginLeft: "20px", width: "100px", height: "44px" }}
              >
                <InputLabel
                  id="role-select-label"
                  style={{ textAlign: "center" }}
                >
                  Select Status
                </InputLabel>
                <Select
                  labelId="role-select-label"
                  label="SelectStatus"
                  // value={filterAll.Role || ""}
                  value={statusValue || ""}
                  onChange={handleStatusFilterChange}
                  fullWidth
                  // style={{ marginLeft: "20px", width: "150px", height: "44px" }}
                  style={{ width: "150px", height: "44px" }}
                > 
                  <MenuItem value="">Reset</MenuItem>
                  {selectStatusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ): null}
          </>
        )}
        {/* <IconButton
          onClick={handleResetFilters}
          color="primary"
          style={{
            position: "absolute",
            right: "650px",
          }}
        >
          <RefreshIcon style={{ fontSize: 30 }} />
        </IconButton> */}
        <>
          <TextField
            label="Start Date"
            type="date"
            value={inputStartDate}
            // onChange={(e) => handleStartDateChange(e.target.value)} 
          onChange={(e) => setInputStartDate(e.target.value)}
          onKeyPress={handleKeyPress}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              position: "absolute",
              right: "525px",
              width: "130px",
              marginTop: "1px",
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={inputEndDate}
            // onChange={(e) => handleEndDateChange(e.target.value)}
          onChange={(e) => setInputEndDate(e.target.value)}
          onKeyPress={handleKeyPress}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              position: "absolute",
              right: "385px",
              width: "130px",
              marginTop: "1px",
            }}
          />
        </>
        <Button
          onClick={handleDownloadData}
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            position: "absolute",
            right: "280px",
            height: "44px",
          }}
        >
          {/* Export */}
          {loading ? "Loading..." : "Export"}
        </Button>
        <TextField
          label={`Search by profName,number`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ position: "absolute", right: "80px", width: "190px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          style={{
            position: "absolute",
            right: "10px",
            width: "50px",
            height: "44px",
          }}
        >
          Search
        </Button>
        <div style={{ marginTop: "1rem" }}>
          <FormControl component="fieldset" style={{ marginLeft: "20px" }}>
            <RadioGroup
              aria-label="jobStatus"
              name="jobStatus"
              value={selectedJobStatus}
              onChange={handleJobStatusChange}
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value="current"
                control={<Radio />}
                label="Current Users"
              />
              <FormControlLabel
                value="past"
                control={<Radio />}
                label="Old Users"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <MDBox sx={{ marginTop: "1rem" }}>
          {" "}
          <DataGrid
            density="compact"
            getRowId={(row) => row?._id}
            components={{ Toolbar: GridToolbar }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // name: false,
                  createdAt: true,
                  updatedAt: true,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                // showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 },
                printOptions: { disableToolbarButton: true },
              },
            }}
            slots={{
              loadingOverlay: <CircularProgress disableShrink />,
            }}
            // loading={premiumUsers?.length > 0 ? false : true}
            loading={loading && <CircularProgress />}
            columns={columns}
            // rows={[...premiumUsers]}
            rows={premiumUsers?.length > 0 ? [...premiumUsers] : []}
            pageSize={pageSize}
            rowCount={totalCount}
            paginationMode="server"
            pagination
            page={currentPage}
            onPageSizeChange={(newPageSize) => dispatch(pageLimit(newPageSize))}
            onPageChange={(newPage) => dispatch(pageChanged(newPage))}
            rowsPerPageOptions={[10, 25, 50, 100]}
            sx={{
              backgroundColor: "#ffff",
              color: "grey",
              height: "35rem",
              margin: "1rem",
              borderRadius: "0.5rem",
              "& .MuiDataGrid-cell": {
                padding: "15px",
                fontSize: "12px",
              },
              borderColor: "light-grey",
              "& .MuiDataGrid-cell:hover": {
                color: "info",
              },
            }}
            disableColumnFilter
          />{" "}
        </MDBox>{" "}
      </DashboardLayout>{" "}
      {renderJobCountPopup()}
    </>
  );
};
export default PremiumUser;
