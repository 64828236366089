import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Grid, Link, Paper } from "@mui/material";
import authoritySignature from "assets/images/authoritySignature.png";
import IfanFlexLogo from "assets/images/ifanFlexLogo.png";
import RupeesSymbol from "assets/images/rupees_symbol.jpg";
import jobizoLogo from "assets/images/jobizoLogo.png";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import jsPDF from "jspdf";
// import html2canvas from 'html2canvas';
//import htmlToPdf from 'html-pdf';
// import html2pdf from 'html2pdf.js';

import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBillData } from "../Redux/actionCreator";
import "./GigBillStyle.css";
import { transactionSlice } from "./../../transaction/Redux/reducer";

const GigBillTax = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { Bill_Data } = useSelector((state) => state.bills);
  var gigBillData = Bill_Data?.billInfo;

  // console.log("gigBillData>>>", gigBillData);

  const path2 = window.location.pathname.split("view1/")[1];
  //   console.log("path2", path2);

  const userInvoiceData = gigBillData[0]?.userInvoices
    ? gigBillData[0]?.userInvoices
    : [];

  const convert = (str) => {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [day, month, date.getFullYear()].join("-");
  };

  const lastPart = gigBillData[0]?.billNumber.split("/").pop();
  //   console.log("lastPart>>",lastPart)

  async function printDocument1() {
    let jsPdf = new jsPDF("p", "pt", "letter");
    var htmlElement = document.getElementById("invoiceContainer1");
    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(`${lastPart}.pdf`);
        window.open(jsPdf.output("bloburl"));
      },
      margin: 10,
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.45,
      },
    };

    jsPdf.html(htmlElement, opt);
  }

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    dispatch(fetchBillData(path2));
  }, [path2, dispatch]);

  const Part1 = gigBillData[0]?.billNumber.split("/").slice(-3, -2)[0];
  const Part2 = gigBillData[0]?.billNumber
    .split("/")
    .slice(-2, -1)[0]
    .replace("-", "");
  const Part3 = gigBillData[0]?.billNumber.split("/").pop();
  const transformedPart = isNaN(Part3) ? Part3 : "D" + Part3;
  const result = Part1 + "/" + Part2 + "/" + transformedPart;
  // let debitNumber = gigBillData[0]?.billNumber;
  let debitNumber = result;

  console.log(">>", gigBillData);
  let invoiceAmount =
    gigBillData[0]?.convensionFee +
    gigBillData[0]?.cgst +
    gigBillData[0]?.sgst +
    gigBillData[0]?.gst;

  console.log("total", invoiceAmount);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>

            <MDButton
              variant="contained"
              color="info"
              onClick={() => {
                printDocument1();
                // handleDownloadData()
              }}
            >
              Export as PDF
            </MDButton>
          </Grid>
        </MDBox>
        <div id="invoiceContainer1">
          <Paper sx={{ marginTop: "1rem" }}>
            <MDBox className={"invoiceBox"}>
              <div className="invoiceHeader">
                <img src={IfanFlexLogo} alt="logo" className="logoStyle" />

                <div className="headerContent">
                  <h4>Duplicate Copy</h4>
                  <br />
                  <small>Plot No.89, 3Floor, Sector 44</small>
                  <br />
                  <small>Gurgaon - 122003, Haryana (India)</small>
                  <br />
                  <small>Email: info@jobizo.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small>
                  <br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small>
                  <br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <th
                      colSpan={3}
                      style={{
                        backgroundColor: "lightgrey",
                        textAlign: "center",
                      }}
                    >
                      TAX INVOICE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Invoice No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>{gigBillData[0]?.billNumber}</td>
                    <td>
                      {moment(gigBillData[0]?.billingDate).format("DD-MM-YYYY")}{" "}
                      &nbsp; &nbsp; &nbsp;
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Official Address:Plot No 89, 3rd Floor, Sector 44 Gurugram{" "}
                      <br />
                      122003
                    </td>
                    <td colSpan={2}>
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Client:</th>
                    <th colSpan={2}>Bank Details:</th>
                  </tr>
                  <tr>
                    <td>
                      {gigBillData[0]?.buyer?.address?.hospitalName} <br />
                      Official Address:{" "}
                      {gigBillData[0]?.buyer?.address?.address?.address}, &nbsp;
                      {/* <br /> */}
                      {gigBillData[0]?.buyer?.address?.address?.city}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.state}, &nbsp;
                      {gigBillData[0]?.buyer?.address?.address?.country}
                      <br />
                      {gigBillData[0]?.buyer?.address?.address?.pin}
                    </td>

                    <td rowSpan={1} colSpan={2}>
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={1} colSpan={1}>
                      State Code: {gigBillData[0]?.buyer?.stateCode}
                      <br />
                      Reverse charges applicable:{" "}
                      {gigBillData[0]?.buyer?.reverseChargesApplicable}
                      <br />
                      GSTIN: {gigBillData[0]?.buyer?.gst}
                    </td>

                    {/* <tr>
                      <td>Reference Debit No:</td>
                      <td
                      // style={{ borderRight: "none" }}
                      >
                        {debitNumber}
                      </td>
                    </tr>
                    <tr>
                      <td>Reference Debit Date:</td>
                      <td
                      // style={{ borderRight: "none" }}
                      >
                        {moment(gigBillData[0]?.billingDate).format(
                          "DD-MM-YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Due Date:</td>
                      <td
                      //  style={{ borderRight: "none" }}
                      ></td>
                    </tr> */}

                    <td style={{ paddingTop: "30px" }}>
                      Reference Debit No: <hr class="hr-line" />
                      Reference Debit Date: <hr class="hr-line" />
                      Payment Due Date:
                    </td>
                    <td>
                      {debitNumber} <hr class="hr-line" />
                      {moment(gigBillData[0]?.billingDate).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      &nbsp; &nbsp; &nbsp; <hr class="hr-line" />
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">
                      Description of Service
                    </th>
                    <th className="textAlignmentCenter">Amount</th>
                  </tr>
                  <tr>
                    <td className="textAlignmentCenter">1</td>
                    <td className="textFit">
                      Convenience Fee
                      {/* for the month of September-22 */}
                    </td>
                    {/* <td className="textAlignmentRight">
                      ₹ {gigBillData[0]?.convensionFee}
                    </td> */}
                    <td
                      style={{
                        textAlign: "right",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <img
                        src={RupeesSymbol}
                        alt="Rupees"
                        style={{
                          height: "23px",
                          width: "23px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        {gigBillData[0]?.convensionFee}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Sub Total</td>
                    {/* <td className="textAlignmentRight">
                      ₹ {gigBillData[0]?.convensionFee}
                    </td> */}
                    <td
                      style={{
                        textAlign: "right",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <img
                        src={RupeesSymbol}
                        alt="Rupees"
                        style={{
                          height: "23px",
                          width: "23px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        {gigBillData[0]?.convensionFee}
                      </span>
                    </td>
                  </tr>

                  {/* <tr>
                    <td colSpan={2}>
                      <b>Add: CGST @ 9%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>
                      <b>Add: SGST @ 9%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>Add: IGST @ 18%</b>
                    </td>

                    <td>{gigBillData[0]?.gst}</td>
                  </tr> */}

                  {!gigBillData[0]?.cgst || !gigBillData[0]?.sgst ? (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        {/* <td className="textAlignmentRight">
                          ₹ {gigBillData[0]?.gst}
                        </td> */}
                        <td
                          style={{
                            textAlign: "right",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <img
                            src={RupeesSymbol}
                            alt="Rupees"
                            style={{
                              height: "23px",
                              width: "23px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {gigBillData[0]?.gst}
                          </span>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        {/* <td className="textAlignmentRight">
                          ₹ {gigBillData[0]?.cgst}
                        </td> */}
                        <td
                          style={{
                            textAlign: "right",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <img
                            src={RupeesSymbol}
                            alt="Rupees"
                            style={{
                              height: "23px",
                              width: "23px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {gigBillData[0]?.cgst}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        {/* <td className="textAlignmentRight">
                          ₹ {gigBillData[0]?.sgst}
                        </td> */}
                        <td
                          style={{
                            textAlign: "right",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <img
                            src={RupeesSymbol}
                            alt="Rupees"
                            style={{
                              height: "23px",
                              width: "23px",
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          />
                          <span
                            style={{
                              display: "inline-block",
                              verticalAlign: "middle",
                            }}
                          >
                            {gigBillData[0]?.sgst}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td className="textAlignmentRight">{}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td colSpan={2}>
                      <b>Total</b>
                    </td>
                    {/* <td className="textAlignmentRight">₹ {invoiceAmount}</td> */}
                    <td
                      style={{
                        textAlign: "right",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      <img
                        src={RupeesSymbol}
                        alt="Rupees"
                        style={{
                          height: "23px",
                          width: "23px",
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        {invoiceAmount.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="invoiceFooter">
                <b>
                  {/* Note <br /> * TDS to be deducted at @ 2% under 194C */}
                  Note <br /> * TDS to be deducted at @{" "}
                  {gigBillData[0]?.branch?.tds
                    ? gigBillData[0]?.branch?.tds
                    : 10}
                  % under 194C
                </b>
                <br />
                <p>Terms & Conditions</p>
                <div class="invoiceList">
                  <div class="numberedItem">
                    Subject to Delhi Jurisdiction only
                  </div>
                  <div class="numberedItem">
                    TDS certificate to be shared as soon as it is generated
                  </div>
                  <div class="numberedItem">
                    Service Accounting Code: {gigBillData[0]?.sac}
                  </div>
                  <div class="numberedItem">
                    Late Payment Fees of 1.5% per month would be charged after
                    the due date
                  </div>
                  <div class="numberedItem">
                    Invoice raised will be deemed correct and accepted by you,
                    unless any discrepancy is reported to us in writing, within
                    7 days from the date of receipt of the Invoice.
                  </div>
                </div>

                <div>
                  <p className="pStyle">For IFAN Flex Pvt. Ltd.</p>
                  <img
                    src={authoritySignature}
                    alt="logosign"
                    className="logosStyle"
                  />
                  <p className="footerText">
                    No Signature required as it's a system generated Invoice
                  </p>
                </div>

                <hr />
                <div className="footerStyle">
                  <p>Office: +91 124.487 5800</p>
                  <p>Fax : +91 124.401 4035</p>
                  <p>CIN: U74999DL2020PTC373866</p>
                </div>
              </div>

              <br />
            </MDBox>
          </Paper>
        </div>
      </DashboardLayout>
    </>
  );
};

export default GigBillTax;
